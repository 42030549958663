import controller_0 from '@symfony/ux-live-component/dist/live_controller.js';
import '@symfony/ux-live-component/dist/live.min.css';
import controller_1 from 'vite-plugin-symfony/stimulus/helpers/react/render_controller';
import controller_2 from '@symfony/ux-turbo/dist/turbo_controller.js';

export default {
'live': controller_0,
'symfony--ux-react--react': controller_1,
'symfony--ux-turbo--turbo-core': controller_2
};
